const WEEK_FROM = 9;
const WEEK_TO = 23;

const SPECIAL_WEEK_FROM = 6;
const SPECIAL_WEEK_TO = 24;

const SUNDAY_FROM = 10;
const SUNDAY_TO = 22;

const MIN_RESERVATION_HOURS = 4;
const MAX_RESERVATION_HOURS = 12;

const MIN_RESERVATION_MINUTES = MIN_RESERVATION_HOURS * 60;
const MAX_RESERVATION_MINUTES = MAX_RESERVATION_HOURS * 60;

export default {
  week: {
    from: WEEK_FROM,
    to: WEEK_TO
  },
  sunday: {
    from: SUNDAY_FROM,
    to: SUNDAY_TO
  },
  specialWeek: {
    from: SPECIAL_WEEK_FROM,
    to: SPECIAL_WEEK_TO
  },
  WEEK_FROM,
  WEEK_TO,
  SUNDAY_FROM,
  SUNDAY_TO,
  SPECIAL_WEEK_FROM,
  SPECIAL_WEEK_TO,
  MIN_RESERVATION_HOURS,
  MAX_RESERVATION_HOURS,
  MIN_RESERVATION_MINUTES,
  MAX_RESERVATION_MINUTES
};
