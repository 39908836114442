




























































































































































/* eslint-disable no-underscore-dangle */
import Vue, { VueConstructor } from "vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown.vue";
import ZInputAddress from "@zubut/common/src/components/ZInputAddress.vue";
import DeliveryMethods from "@zubut/common/src/components/DeliveryMethods.vue";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker.vue";
import { formatPhone, validateName } from "@zubut/common/src/utils/strings";
import vClickOutside from "@zubut/common/src/directives/click-outside";
import FavoriteAddresses from "@/app/components/FavoriteAddresses.vue";
import PlatformSchedule from "@/constants/platform/schedule";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import Clients from "@/services/clients";
import VehicleTypes from "@/constants/vehicles/type";
import { format } from "@zubut/common/src/utils/time";
import Reservations from "@/services/reservations";
import { formatMoney } from "@zubut/common/src/utils/money";
import currency from "currency.js";
import {
  parseISO,
  startOfDay,
  toDate,
  getDay,
  getHours,
  getMinutes,
  addDays,
  differenceInCalendarDays,
  set
} from "date-fns";
import { formatSendPhone } from "@zubut/common/src/utils/strings";
import NotifyMixin, { NotifyOptions } from "@/mixins/notify.vue";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof NotifyMixin>
>).extend({
  name: "HourRentRequestPanel",

  components: {
    DeliveryMethods,
    FavoriteAddresses,
    ZDatePicker,
    ZDropdown,
    ZInputAddress
  },

  directives: {
    clickOutside: vClickOutside
  },

  mixins: [NotifyMixin],

  props: {
    event: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      default: true
    },
    left: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    reservationVehicleType: {
      type: Number,
      default: VehicleTypes.NUM_MOTORCYCLE
    }
  },

  data() {
    return {
      customDateFilter: new Date(),
      datePickerConfig: {
        format: "DD/MM/YYYY",
        useCurrent: true,
        disabledDates: {
          to: toDate(addDays(new Date(), 1))
        }
      },
      driverCount: 1 as any,
      prices: {
        Motorcycle: null,
        Eco: null,
        Car: null
      } as any,
      timeOptions: [] as any[],
      selectedStartTime: null as any,
      selectedEndTime: null as any,
      origin: null,
      vehicleType: VehicleTypes.NUM_MOTORCYCLE,
      address: {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        position: null,
        valid: false
      } as any,
      rates: {
        subtotal: 0,
        hours: 0
      },
      specialSchedule: this.$store.state.user.additional?.specialSchedule
        ? this.$store.state.user.additional.specialSchedule
        : false
    };
  },

  computed: {
    addresses(): any {
      return this.$store.getters["user/getAddresses"];
    },

    isZubut(): boolean {
      return this.$store.getters["isZubut"];
    },

    userAddress(): any {
      return this.$store.getters["user/getDefaultAddressParsed"];
    },

    savedAddress(): any {
      return this.$store.state.rents.defaultAddress;
    },

    isAddressFavorite(): boolean {
      return this.address.id ? true : false;
    },

    disableControls(): boolean {
      if (this.event && this.event.reserved) {
        return true;
      }
      return false;
    },

    isValidForm(): boolean {
      return (
        this.isEventDurationAllowed &&
        this.isOriginSet &&
        !this.disableControls &&
        this.validDriverCount
      );
    },

    startTimeOptions(): any[] {
      return this.timeOptions.reduce((arr: any[], item: any) => {
        if (item.value >= 20) {
          arr.push({ ...item, disabled: true });
          return arr;
        }
        arr.push(item);
        return arr;
      }, []);
    },

    endTimeOptions(): any[] {
      return this.timeOptions.reduce((arr: any[], item: any) => {
        if (
          item.value <= 11 ||
          (this.selectedStartTime && item.value <= this.selectedStartTime)
        ) {
          arr.push({ ...item, disabled: item.value != 0 ? true : false });
          return arr;
        }
        arr.push(item);
        return arr;
      }, []);
    },

    favoriteAddresses(): any[] {
      return this.$store.getters["user/getAvailableAddresses"];
    },

    type(): number {
      return this.$store.state.user.userType;
    },

    eventDuration(): number {
      if (this.selectedStartTime && this.selectedEndTime) {
        return this.selectedEndTime - this.selectedStartTime;
      }
      return 0;
    },

    isEventDurationAllowed(): boolean {
      return (
        this.eventDuration >= PlatformSchedule.MIN_RESERVATION_HOURS &&
        this.eventDuration <= PlatformSchedule.MAX_RESERVATION_HOURS
      );
    },

    validDriverCount(): boolean {
      return this.driverCount >= 1 && this.driverCount <= this.maxDrivers
        ? true
        : false;
    },

    maxDrivers(): number {
      return this.$store.state.rents.maxDrivers;
    },

    clientId(): string {
      return this.$store.state.user.id;
    },

    validAddress(): boolean {
      return (
        this.address?.address?.address?.length > 0 &&
        this.address?.valid === true
      );
    },

    validPhone(): boolean {
      return this.address.phone.length === 14 ? true : false;
    },

    validName(): boolean {
      return validateName(this.address.name) ? true : false;
    },

    isOriginSet(): boolean {
      if (this.validName && this.validPhone && this.validAddress) {
        return true;
      } else {
        return false;
      }
    },

    formattedSubtotal(): string {
      return formatMoney(this.rates.subtotal);
    },
    hrefLink(): string {
      return this.isZubut
        ? "https://zubut.com/terminos-y-condiciones"
        : "https://mensajerosurbanos.com/politicas-de-privacidad-mexico/?lang=mx";
    }
  },

  watch: {
    event: {
      immediate: true,
      handler(newEvent) {
        if (newEvent) {
          const start = parseISO(newEvent.start);
          const end = parseISO(newEvent.end);
          this.customDateFilter = toDate(startOfDay(start));
          const startValue = getMinutes(start)
            ? getHours(start) + 0.5
            : getHours(start);
          const endValue = getMinutes(end)
            ? getHours(end) + 0.5
            : getHours(end);
          this.selectedStartTime = startValue;
          this.selectedEndTime = endValue;
          if (this.selectedEndTime === 0) this.selectedEndTime = 24;
          this.driverCount = newEvent.drivers || 1;
        }
      }
    },

    address: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.handleSave();
        }
      }
    },

    selectedEndTime(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.handleSave(false, null, oldVal);
      }
    },

    selectedStartTime(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.handleSave(false, oldVal);
      }
    },

    reservationVehicleType: {
      immediate: true,
      handler(newVal) {
        this.vehicleType = newVal;
      }
    },

    isValidForm(val) {
      if (val === true) {
        this.handleEstimation();
      }
    },

    "address.addressText": {
      handler() {
        this.address.valid = false;
      }
    }
  },

  created() {
    this.setAddress();
    if (this.isOriginSet) {
      this.handleEstimation();
    }
    this.timeOptions = this.createTimeOptions();
  },

  beforeMount() {
    this.getRatesReservation();
  },

  methods: {
    setAddress() {
      if (this.savedAddress) {
        const {
          id,
          address,
          information,
          name,
          phone,
          position
        } = this.savedAddress;
        this.address.id = id;
        this.address.address = { id, address, position };
        this.address.addressText = address;
        this.address.name = name;
        this.address.phone = formatPhone(phone);
        this.address.information = information;
        this.address.position = position ? { ...position } : null;
        this.$nextTick(() => {
          this.address.valid = true;
        });
      } else if (this.userAddress) {
        const { id, address, information, name, phone } = this.userAddress.data;
        this.address.id = id;
        this.address.address = { ...address };
        this.address.addressText = address.address;
        this.address.name = name;
        this.address.phone = formatPhone(phone);
        this.address.information = information;
        this.address.position = address.position
          ? { ...address.position }
          : null;
        this.$nextTick(() => {
          this.address.valid = true;
        });
      }
    },
    changeDeliveryMethod(vehicleType: number) {
      this.vehicleType = vehicleType;
    },
    createTimeOptions(): any[] {
      const res = [];
      const startHour = this.specialSchedule ? 6 : 9;
      for (let i = startHour; i <= 23; i++) {
        let meridiem = i >= 12 ? "pm" : "am";
        let hour = i > 12 ? i - 12 : i;

        res.push({ value: i, text: `${hour}:00 ${meridiem}` });

        if (i !== 23 && !this.specialSchedule) {
          res.push({ value: i + 0.5, text: `${hour}:30 ${meridiem}` });
        }
      }
      if (this.specialSchedule) {
        res.push({ value: 24, text: `12:00 am` });
      }
      return res;
    },

    handleCancel() {
      this.$emit("cancel", this.event.reserved);
    },

    handleSave(closeRequestPanel = false, oldStart = null, oldEnd = null) {
      const { start, end } = this.buildDateRange();
      if (this.isValidDate(start, end)) {
        this.handleEstimation();
        const data = {
          start: format(start, "yyyy-MM-dd'T'HH:mm:ss"),
          end: format(end, "yyyy-MM-dd'T'HH:mm:ss"),
          address: {
            ...cloneDeep(this.address.address),
            name: this.address.name,
            phone: formatSendPhone(this.address.phone),
            information: this.address.information
          },
          drivers: parseInt(this.driverCount)
        };
        this.$emit("save", {
          data,
          closeRequestPanel,
          vehicleType: this.vehicleType
        });
      } else if (oldStart) {
        this.selectedStartTime = oldStart;
      } else if (oldEnd) {
        this.selectedEndTime = oldEnd;
      }
    },

    buildDateRange() {
      const day = this.customDateFilter;
      const startIsHalfHour =
        (this.selectedStartTime + "").split(".")[1] || false;
      const startMinutes = startIsHalfHour ? 30 : 0;
      const endIsHalfHour = (this.selectedEndTime + "").split(".")[1] || false;
      const endMinutes = endIsHalfHour ? 30 : 0;
      const start = set(startOfDay(day), {
        hours: this.selectedStartTime,
        minutes: startMinutes
      });
      const end = set(startOfDay(day), {
        hours: this.selectedEndTime,
        minutes: endMinutes
      });
      return { start, end };
    },

    handleAdressChange({ destination, complete }: any) {
      this.origin = { ...destination, complete };
    },

    handleAutocompleteAddressChange(address: any) {
      this.address.address = {
        address: address.address,
        position: address.position
      };
      this.address.addressText = address.address;
      this.address.position = address.position;
      this.$nextTick(() => {
        this.address.valid = true;
      });
    },

    handleFavoriteAddressChange(address: any) {
      this.address.id = address.id;
      this.address.clientId = address.clientId;
      this.address.address = {
        address: address.address,
        position: address.position
      };
      this.address.addressText = address.address;
      this.address.phone = formatPhone(address.phone);
      this.address.name = address.name;
      this.address.information = address.information || "";
      this.$nextTick(() => {
        this.address.valid = true;
      });
    },

    saveDestinationOnFavorites() {
      if (this.isValidForm) {
        const favorite = {
          id: this.address.id,
          clientId: this.address.clientId,
          address: this.address.address.address,
          position: this.address.address.position,
          name: this.address.name,
          phone: this.address.phone,
          information: this.address.information
        };
        this.$store
          .dispatch("user/createOrUpdateFavoriteAddress", favorite)
          .then(res => {
            this.address.id = res.id;
            this.address.clientId = res.clientId;
          })
          .catch(err => {
            this.$captureError(err);
          });
      }
    },

    handleClickaway() {
      this.$emit("clickaway");
    },

    isValidDate(start: any, end: any) {
      const { sunday, week, specialWeek } = PlatformSchedule;
      const startTime = getHours(start) + getMinutes(start) / 100;
      const endTime = getHours(end) + getMinutes(end) / 100;
      const weekSchedule = this.specialSchedule ? specialWeek : week;
      const sundaySchedule = this.specialSchedule ? specialWeek : sunday;
      const isWithinServiceHours =
        startTime >= weekSchedule.from && endTime <= weekSchedule.to;
      const isWithinSameDay =
        getDay(start) === getDay(end) ||
        (differenceInCalendarDays(startOfDay(end), startOfDay(start)) === 1 &&
          getHours(end) === 0);
      const isWithinSundayServiceHours =
        getDay(start) === 0
          ? startTime >= sundaySchedule.from && endTime <= sundaySchedule.to
          : true;
      if (!isWithinSameDay) {
        this.notify({
          type: "warn",
          text: "El servicio debe estar agendado dentro del mismo dia"
        } as NotifyOptions);
        return false;
      }

      if (!isWithinSundayServiceHours || !isWithinServiceHours) {
        this.notify({
          type: "warn",
          text: "La reservación debe ser dentro de las horas de servicio"
        } as NotifyOptions);
        return false;
      }

      if (!this.isEventDurationAllowed) {
        this.notify({
          type: "warn",
          text: "Seleccione mínimo 4 y máximo 12 horas de reservación"
        } as NotifyOptions);
        return false;
      }
      return true;
    },

    getRatesReservation() {
      Clients.getRatesReservation(this.clientId)
        .then(res => {
          const {
            bikeHourNighttime: bikeHourMax,
            bikeHourWeekRange2: bikeHourMin,
            carHourNighttime: carHourMax,
            carHourWeekRange2: carHourMin
          } = res as any;
          const tax = 1.16;
          if (bikeHourMin && bikeHourMax) {
            this.prices.Motorcycle = {
              min: currency(bikeHourMin).multiply(tax),
              max: currency(bikeHourMax).multiply(tax)
            };
          } else {
            this.prices.Motorcycle = null;
          }

          if (carHourMin && carHourMax) {
            this.prices.Car = {
              min: currency(carHourMin).multiply(tax),
              max: currency(carHourMax).multiply(tax)
            };
          } else {
            this.prices.Car = null;
          }

          this.prices.Eco = null;
        })
        .catch(error => {
          this.$captureError(error);
        });
    },

    handleEstimation() {
      const { start, end } = this.buildDateRange();
      const reservationBlock = {
        start: format(start, "yyyy-MM-dd'T'HH:mm:ss"),
        end: format(end, "yyyy-MM-dd'T'HH:mm:ss"),
        driverNo: parseInt(this.driverCount)
      };

      const data = {
        blocks: [reservationBlock],
        clientId: this.clientId,
        vehicleType: this.vehicleType,
        origin: {}
      };

      Reservations.estimation(data).then((res: any) => {
        this.rates.subtotal = res.cost;
        this.rates.hours = res.hours;
      });
    }
  }
});
