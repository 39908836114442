<template>
  <div class="border rounded p-4">
    <hour-rent-calendar-header
      @reserve="$emit('reserve')"
      @quick-reservation="
        reservationWeek => $emit('quick-reservation', reservationWeek)
      "
    />
    <loading-spinner v-if="loadingCalendar" />

    <full-calendar
      v-if="!loadingCalendar"
      ref="calendar"
      class="calendar fc fc-media-screen fc-direction-ltr fc-theme-standard"
      :class="{
        'disable-monday': disableMonday,
        'special-schedule': specialSchedule
      }"
      :options="calendarOptions"
    />
  </div>
</template>

<script>
import HourRentCalendarHeader from "./HourRentCalendarHeader";
import ReservationStatus from "@/constants/reservations/status";

import "@fullcalendar/core/vdom";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { addDays, isSameDay } from "date-fns";

/* Lazy loaded components */
const FullCalendar = () => import("@fullcalendar/vue");

export default {
  name: "HourRentCalendar",

  components: {
    FullCalendar,
    HourRentCalendarHeader
  },

  props: {
    header: {
      type: Object,
      default() {
        return {
          left: "prev,title,next",
          center: "",
          right: ""
        };
      }
    },

    defaultView: {
      type: String,
      default() {
        return "timeGridWeek";
      }
    },

    modalOpen: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  data() {
    return {
      calendar: null,
      currentEvents: [],
      requestPanelProps: {
        show: false,
        left: 0,
        top: 0
      },
      loadingCalendar: true,
      loadingEvents: false,
      disableMonday: false,
      specialSchedule: this.$store.state.user.additional?.specialSchedule
        ? this.$store.state.user.additional.specialSchedule
        : false
    };
  },

  computed: {
    calendarOptions() {
      return {
        plugins: [timeGridPlugin, momentPlugin, interactionPlugin],
        initialView: this.defaultView,
        slotDuration: "01:00:00", //Duration of a row
        eventMinHeight: 120, //Minimum time for an event
        slotMinTime: this.specialSchedule ? "06:00:00" : "09:00:00", //First hour of the day
        slotMaxTime: this.specialSchedule ? "24:00:00" : "23:00:00", //Last hour of the day
        allDaySlot: false, //Disable a allDay row
        firstDay: 1, //Set Monday as First Day
        titleFormat: "D / MMMM",
        slotLabelFormat: "h A",
        eventColor: "#6F6F6F",
        defaultTimedEventDuration: "04:00",
        height: "auto",
        headerToolbar: this.header,
        locale: "esLocale",
        selectable: this.modalOpen ? false : true,
        editable: this.modalOpen ? false : true,
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short"
        },
        events: this.$store.state.rents.events,
        eventOverlap: false,
        eventContent: arg => {
          const title = !arg.event.extendedProps.address?.address
            ? "Ingresa Origen"
            : arg.event.extendedProps.address.address;
          const drivers = !arg.event.extendedProps.drivers
            ? 1
            : arg.event.extendedProps.drivers;

          return {
            html:
              "<div> <div class='font-weight-bold'>" +
              title +
              "</div><div>" +
              moment(arg.event.start).format("h:mma") +
              " - " +
              moment(arg.event.end).format("h:mma") +
              "</div><div class='pt-3'> " +
              `<img src="${require("@/assets/img/driver-white.png")}">` +
              " " +
              drivers +
              "</div></div>"
          };
        },

        validRange: nowDate => {
          return { start: nowDate };
        },

        select: (event, view) => {
          this.$emit("event-render", event, view);
        },

        eventClick: arg => {
          this.$emit("event-selected", arg.event, arg.el);
        },

        eventDrop: arg => {
          this.$emit("event-drop", arg.event, arg.delta, arg.revert);
        },

        eventResize: arg => {
          this.$emit("event-resize", arg.event, arg.delta, arg.revert);
        },

        now: () => {
          if (this.calendar?.getDate) {
            // the value of dateFromCalendar when the component renders => date of the system.
            // the value of dateFromCalendar when you change to the next week => it takes the first date of the week.
            const dateFromCalendar = this.calendar?.getDate();
            const nextDayOfTheSystem = addDays(new Date(), 1);
            this.disableMonday = isSameDay(
              dateFromCalendar,
              nextDayOfTheSystem
            );
          } else {
            this.calendar = this.$refs?.calendar?.getApi();
          }
        },

        //Change days header
        dayHeaderContent: arg => {
          return {
            html: `
            <div class='py-3 d-flex flex-column align-items-center'>
                <div>${moment(arg.date).format("D")}</div>
                <div class='day-name'>${moment(arg.date)
                  .format("ddd")
                  .toUpperCase()
                  .replace(".", "")}</div>
            </div>`
          };
        },
        //Styles
        slotLaneClassNames: "table-cells",
        slotLabelClassNames: "border-0 slot-labels"
      };
    }
  },

  beforeMount() {
    this.$store.dispatch("rents/removeNotSavedEvents");
    this.requestReservations();
  },

  mounted() {
    FullCalendar().finally(() => {
      this.loadingCalendar = false;
    });
  },

  methods: {
    requestReservations() {
      this.loadingEvents = true;
      const endOfTommorrow = moment()
        .endOf("day")
        .add(1, "days");
      const startOfSelectedWeek = moment().startOf("week");
      let start = startOfSelectedWeek;
      if (!moment(startOfSelectedWeek).isAfter(endOfTommorrow)) {
        start = endOfTommorrow;
      }

      start = start.format();
      const end = moment()
        .endOf("week")
        .format();
      this.$store
        .dispatch("rents/getReservations", {
          where: {
            range: {
              from: start,
              until: end
            },
            status: [
              { eq: ReservationStatus.NUM_RESERVED },
              { eq: ReservationStatus.NUM_ACTIVE }
            ]
          }
        })
        .finally(() => {
          this.loadingEvents = false;
        });
    },

    updateEvents() {
      this.options.events.forEach((event, index) => {
        this.options.event[index].tittle = event.address.address;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.calendar {
  margin-top: 14px;
}

::v-deep .fc-scrollgrid {
  border-radius: 10px;
  overflow: hidden;
}
::v-deep .fc-toolbar-chunk {
  div:first-child {
    display: flex;
  }
}

::v-deep .fc-timegrid-axis {
  border: none !important;
}

::v-deep .fc-button-primary {
  background-color: var(--primary);
  height: 32px;
  border-radius: 0px;
  width: 31px;
  border: none;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--primary) !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: $solitude-2;

    .fc-icon-chevron-left {
      color: $comet !important;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $solitude-2 !important;
    }
  }
  .fc-icon-chevron-left,
  .fc-icon-chevron-right {
    transform: translate(-3px, -1px);
  }
}

::v-deep .fc-prev-button {
  border-radius: 6px 0 0 6px;
}

::v-deep .fc-next-button {
  border-radius: 0 6px 6px 0;
}

::v-deep .fc-icon {
  transform: translate(-8px, 0);
}

::v-deep .fc-toolbar-title {
  font-family: Montserrat;
  font-size: 12px;
  border: 1px solid #d4d7df;
  height: 32px;
  width: 220px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .slot-labels {
  color: #656b7f;
  font-family: Montserrat;
  font-size: 8px;
  transform: translate(0, -5px);
  width: 56px !important;
}

::v-deep .fc-day-disabled {
  background-color: white;
}

::v-deep .day-name {
  color: black;
  font-size: 10px;

  &:hover {
    text-decoration: none;
  }
}

::v-deep .fc-day-today {
  background-color: white;
}

::v-deep .fc .fc-timegrid-col.fc-day-today {
  background-color: white !important;
}

::v-deep .fc-timegrid-col {
  &.fc-day {
    border: 1px solid #d4d7df;
    height: calc(34px * 14);
  }
}

/* Color sunday from 10pm - 11pm as disabled */
::v-deep .fc-day-sun {
  .fc-timegrid-col-frame {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 34px !important;
      width: 100%;
      top: calc((100% / 14) * 13);
      background: $solitude;
      border-radius: 6px;
    }
  }

  .fc-timegrid-col-bg {
    background: $solitude !important;
    height: 34px;
    border-radius: 8px;
  }
}

::v-deep .disable-monday .fc-day-future:nth-child(2),
::v-deep .fc-day-disabled,
::v-deep .fc-day-today,
::v-deep .fc-day-today + .fc-day-sunday,
::v-deep .fc-day-today + .fc-day-future {
  .fc-timegrid-col-frame {
    position: relative;
    background-color: $solitude;
    z-index: 2;
    width: 96%;
    left: 2%;
    right: 2%;
    border-radius: 6px;
    height: calc((34px * 14)) !important;

    &::before,
    &::after {
      position: absolute;
      display: inline;
      font-size: 10px;
    }
    &::before {
      padding-top: 16px;
      padding-left: 12px;
      content: "No Disponible    ";
      white-space: pre;
      font-weight: bold;
      font-size: 10px;
      color: $comet;
      top: 0;
      z-index: 3;
    }
    &::after {
      padding-top: 30px;
      padding-left: 12px;
      content: "Reserva con 48 hrs \A de anticipación";
      white-space: pre;
      color: $comet;
    }
  }
}

::v-deep .fc-col-header-cell {
  &.fc-day-today {
    .flex-column div:first-child {
      color: var(--primary) !important;
      position: relative;
      background-color: var(--primary-bg) !important;
      height: 20px;
      width: 20px;
      border-radius: 6px;
    }

    .day-name {
      top: -5px !important;
      color: var(--primary) !important;
    }
  }
}

::v-deep .table-cells {
  height: 34px;
}
::v-deep .fc-col-header-cell-cushion {
  color: black;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

::v-deep .fc .fc-highlight {
  background: none;
}

::v-deep .fc-event-main {
  position: relative;
  font-size: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 12px;

  &::before {
    position: absolute;
    font-size: 15px;
    display: flex;
    content: "=";
    height: 95%;
    width: 85%;
    align-items: flex-end;
    justify-content: center;
  }
}
//No reserved events
::v-deep .fc-event-draggable {
  background: var(--event-primary-bg) !important;
}

::v-deep .special-schedule {
  .fc-day-today + .fc-day-future {
    .fc-timegrid-col-frame {
      height: calc((34px * 18)) !important;
    }
  }
  .fc-day-sun {
    .fc-timegrid-col-frame {
      &::before {
        display: none;
      }
    }

    .fc-timegrid-col-bg {
      display: none;
    }
  }
}
</style>
