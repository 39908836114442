<template>
  <div>
    <div class="hour-rent-header d-flex justify-content-between ">
      <div class="d-flex">
        <div class="d-flex">
          <service-type-avatar :type="2" size="xl" class="mr-3" />
          <div>
            <p class="mb-0 font-weight-bold title">
              {{ ServiceType.RENT_PER_HOUR }}
            </p>
            <p class="mb-0 rules">
              Crea tu reservación (mínimo 4 horas)
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex align-self-end">
        <z-button
          class="header-button mb-3 mb-md-0 w-100 d-block d-md-inline-block mr-3"
          variant="secondary"
          @click="handleCancel()"
        >
          Cancelar
        </z-button>
        <z-button
          v-b-modal="'hour-rent-confirmation-modal'"
          :disabled="disableMakeReservation"
          @click="$emit('reserve')"
        >
          Reservar
        </z-button>
      </div>
    </div>
    <quick-message :show="showMessageReserve" type="info" class="my-3">
      <template #content>
        <div>
          <font-awesome-icon class="mr-2" icon="info-circle" />
          <span>Presiona <b>Reservar</b> para pagar tu reservación.</span>
        </div>
      </template>
    </quick-message>
    <quick-message :show="showMessageSelectOrigin" type="info" class="my-3">
      <template #content>
        <font-awesome-icon class="mr-2" icon="info-circle" />
        Debes seleccionar un punto de origen para tu reservación.
      </template>
    </quick-message>
  </div>
</template>

<script>
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";

export default {
  name: "HourRentCalendarHeader",

  components: {
    ServiceTypeAvatar
  },

  data() {
    return {
      showMessageSelectOrigin: false,
      ServiceType
    };
  },

  computed: {
    disableMakeReservation() {
      return this.eventsPendingToReserve === 0 || this.makingReservation;
    },

    eventsPendingToReserve() {
      const nonReservedEvents = this.$store.getters[
        "rents/getNonReservedEvents"
      ];
      return nonReservedEvents.length;
    },

    enventsWithOutAddress() {
      const noAddressEvents = this.$store.getters["rents/getNoAddressEvents"];
      return noAddressEvents.length;
    },

    isOriginSet() {
      const origin = this.$store.state.rents.defaultAddress;
      return origin || false;
    },

    makingReservation() {
      return this.$store.state.rents.makingReservation;
    },

    showMessageReserve() {
      if (this.eventsPendingToReserve > 0 && this.isOriginSet) {
        return true;
      }
      return false;
    }
  },

  watch: {
    enventsWithOutAddress: "handleShowMessageSelectOrigin"
  },

  methods: {
    handleCancel() {
      this.$router.go(-1);
    },
    handleShowMessageSelectOrigin() {
      setTimeout(() => {
        this.showMessageSelectOrigin = this.enventsWithOutAddress > 0;
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.hour-rent-header {
  .title {
    font-size: 20px;
  }
}

.rules {
  font-size: 12px;
  color: $comet;
}

.svg {
  color: var(--primary);
}
</style>
