<template>
  <z-modal
    id="reservation-confirmation-modal"
    title="Resumen"
    :show="value"
    body-class="p-4"
    hide-footer
    header-class="custom-modal-header"
    size="md"
    @update:show="isVisible => $emit('input', isVisible)"
  >
    <h1 class="text-xl font-weight-bold mb-3">Pagar reservación</h1>
    <quick-message
      :message="rateErrorMessage"
      type="error"
      with-icon
      :show="hasError"
    />
    <time-zone-message class="mb-3 mt-3 rounded" />
    <slot v-if="!isBranch" name="wallet-deposit" v-bind:total="total"> </slot>
    <div>
      <div v-if="user && isAdmin" class="mb-3">
        <div class="font-weight-bold">Usuario</div>
        <div>
          {{ user.name }}
          <b-badge v-if="user.wallet" variant="primary">
            <div class="font-weight-semi-bold">
              Wallet: {{ userWalletAmount }}
            </div>
          </b-badge>
          <b-badge v-else variant="warning">
            El usuario no tiene wallet
          </b-badge>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-2 text-sm">
        <div class="font-weight-bold">Resumen</div>
        <div role="button" class="label-info d-flex align-items-center">
          <z-icon name="Info" size="16" variant="active" />
          <a href="https://zubut.com/terminos-y-condiciones" target="_blank">
            <div class="font-weight-semi-bold text-main ml-1">
              Consultar tarifas
            </div>
          </a>
        </div>
      </div>
      <div v-if="address" class="text-sm">
        {{ address.address }}
      </div>
      <div class="mb-3 text-sm">
        <div>{{ vehicleName }}</div>
      </div>
      <hr />
    </div>

    <div class="text-sm">
      <div
        v-for="(block, index) in formattedBlocks"
        :key="`block-${index}`"
        class="d-flex justify-content-between mb-4 reservation"
      >
        <div>
          <p class="text-sm">
            Reservación {{ index + 1 }}
            <span class="text-dim-gray"> ({{ block.drivers }}) </span>
          </p>
          <p class="block-info pt-2 text-dim-gray">
            {{ block.description }}<br />
          </p>
        </div>

        <div class="d-flex flex-column text-right">
          <div
            role="button"
            class="p-2 text-dim-gray bg-ghost-white rounded"
            v-tooltip.top="'Tarifa diurna'"
            v-if="block.weekdayHours"
          >
            <font-awesome-icon icon="sun" />
            {{ block.weekdayHours }} hrs:
            {{ formatMoney(block.weekdayRate) }}
          </div>
          <div
            role="button"
            class="p-2 text-dim-gray bg-ghost-white rounded"
            :class="{ 'mt-1': 'block.nighttimeHours' }"
            v-tooltip.top="'Tarifa noctura'"
            v-if="block.nighttimeHours"
          >
            <font-awesome-icon icon="moon" />
            {{ block.nighttimeHours }} hrs:
            {{ formatMoney(block.nighttimeRate) }}
          </div>
          <div
            role="button"
            class="p-2 text-dim-gray bg-ghost-white rounded"
            :class="{ 'mt-1': 'block.weekendHours' }"
            v-tooltip.top="'Tarifa de fin de semana'"
            v-if="block.weekendHours"
          >
            <font-awesome-icon icon="calendar-day" />
            {{ block.weekendHours }} hrs:
            {{ formatMoney(block.weekendRate) }}
          </div>
        </div>
      </div>
    </div>

    <div class="price-detail">
      <div class="d-flex">
        <div class="w-50">
          <p class="mb-0">
            {{ $t("tax") }}
          </p>
        </div>
        <div class="text-right w-50">
          <p class="mb-0">{{ formatedIva }}</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex">
      <div class="w-50">
        <p class="mb-0 font-weight-semi-bold">
          Total
        </p>
      </div>
      <div class="text-right w-50">
        <p class="mb-0 font-weight-semi-bold">
          {{ formatedTotal }} {{ formatedCurrency }}
        </p>
      </div>
    </div>
    <quick-message
      class="mt-3"
      message="El cargo se realizará en la cuenta de la empresa."
      type="info"
      :show="isBranch"
    />
    <z-button
      :disabled="disableMakeReservation"
      :loading="loading"
      class="my-3 w-100"
      @click="handleReserve"
    >
      {{ insufficientAmount ? "Saldo Insuficiente en Wallet" : "Pagar" }}
    </z-button>
    <div class="d-flex">
      <div class="pr-2">
        <z-icon name="Info" size="16" variant="active" />
      </div>
      <p class="disclamer-info">
        Una vez realizado el pago no podrás restar horas a tu reservación pero
        si podrás agregar más, sujeto a disponibilidad. Conoce más sobre
        nuestras <a>políticas de renta por hora</a>.
      </p>
    </div>
  </z-modal>
</template>

<script>
import TimeZoneMessage from "./ReservationTimeZoneMessage";
// import InfoIcon from "../assets/icons/ic_info.svg";
import QuickMessage from "./QuickMessage";
import VehicleTypes from "../constants/vehicles/type";
import { formatMoney } from "../utils/money";
import { decimalToHours } from "../utils/time";
import startCase from "lodash/startCase";
import currency from "currency.js";
import { formatISO } from "../utils/time";
import ZModal from "./ZModal";
import ClientTypes from "@zubut/common/src/constants/clients/type";

export default {
  name: "ReservationConfirmationModal",

  components: {
    // InfoIcon,
    TimeZoneMessage,
    QuickMessage,
    ZModal
  },

  props: {
    address: {
      type: Object,
      default: null
    },
    balance: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    estimateFunc: {
      type: Function,
      default: () => {}
    },
    reserveFunc: {
      type: Function,
      default: () => {}
    },
    services: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: true
    },
    vehicleType: {
      type: Number,
      default: VehicleTypes.NUM_MOTORCYCLE
    }
  },

  data() {
    return {
      loading: false,
      hasError: false,
      blocks: [],
      subtotal: 0,
      currency: "",
      rateErrorMessage: ""
    };
  },

  computed: {
    disableMakeReservation() {
      return (
        !this.hasBalance ||
        this.loading ||
        this.hasError ||
        this.insufficientAmount
      );
    },

    userWalllet() {
      if (this.user && this.user.wallet) {
        return this.user.wallet.amount;
      }
      return 0;
    },

    insufficientAmount() {
      if (this.isBranch) {
        return !this.isAdmin && this.total.value > this.balance;
      }
      return !this.isAdmin && this.total.value > this.userWalllet / 100;
    },

    formatedIva() {
      return `${formatMoney(this.iva)}`;
    },

    formattedSubtotal() {
      return `${formatMoney(this.subtotal)}`;
    },

    formatedTotal() {
      return `${formatMoney(this.total)}`;
    },

    hasBalance() {
      return this.isAdmin || this.balance >= this.total;
    },

    iva() {
      return currency(this.subtotal).multiply(0.16);
    },

    total() {
      return currency(this.subtotal).add(this.iva);
    },

    formatedCurrency() {
      return this.currency != null ? this.currency : "";
    },

    userWalletAmount() {
      return formatMoney(this.userWalllet / 100);
    },

    vehicleName() {
      return VehicleTypes.get[this.vehicleType];
    },

    parsedEstimationRequest() {
      return this.services.map(el => {
        return {
          start: el.start,
          end: el.end,
          driverNo: el.drivers
        };
      });
    },

    formattedBlocks() {
      return this.blocks
        .map(block => {
          const { driverNo, start, end } = block;
          const {
            nighttimeHours,
            weekdayHours,
            weekendHours
          } = block.itemization.hours;
          const {
            nighttimeRate,
            weekdayRate,
            weekendRate
          } = block.itemization.rate;
          const duration = nighttimeHours + weekdayHours + weekendHours;
          const month = startCase(formatISO(start, "MMM"));
          const startFormatted = formatISO(
            start,
            `d '${month}' yyyy, h:mm a 'a'`
          );
          const formatedDate = `${startFormatted}  ${formatISO(end, "h:mm a")}`;
          const drivers = `${driverNo} mensajero${driverNo > 1 ? "s" : ""}`;
          const description = `${formatedDate}`;
          return {
            ...block,
            duration,
            description,
            drivers,
            nighttimeHours,
            weekdayHours,
            weekendHours,
            nighttimeRate: currency(nighttimeRate).multiply(nighttimeHours),
            weekdayRate: currency(weekdayRate).multiply(weekdayHours),
            weekendRate: currency(weekendRate).multiply(weekendHours)
          };
        })
        .sort((a, b) => {
          const dateA = new Date(a.start);
          const dateB = new Date(b.start);
          return dateA - dateB;
        });
    },

    isBranch() {
      return this.user?.type === ClientTypes.NUM_BRANCH || false;
    }
  },

  watch: {
    value(newVal) {
      if (newVal) {
        this.hasError = false;
        this.rateReservation();
      }
    }
  },

  methods: {
    formatMoney: formatMoney,

    rateReservation() {
      this.loading = true;
      this.estimateFunc({
        blocks: this.parsedEstimationRequest,
        clientId: this.user?.id,
        vehicleType: this.vehicleType,
        origin: this.address
      })
        .then(rate => {
          this.currency = rate.currency;
          this.blocks = rate.blocks;
          this.subtotal = rate.cost;
        })
        .catch(error => {
          this.hasError = true;
          this.rateErrorMessage =
            error.message || "Error cotizando tu reservación";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleReserve() {
      this.loading = true;
      const createRequestData = () => {
        const vehicleType = this.vehicleType;
        if (this.isAdmin) {
          const information = this.address.information || "Sin especificar";
          const origin = { ...this.address, information };
          const reservationHours = this.services.map(item => ({
            start: item.start,
            end: item.end,
            driverNo: item.drivers
          }));
          const clientId = this.user.id;

          return { origin, reservationHours, clientId, vehicleType };
        } else {
          return { vehicleType };
        }
      };
      this.reserveFunc(createRequestData()).finally(() => {
        this.loading = false;
      });
    },

    decToHour(duration) {
      return decimalToHours(duration);
    }
  }
};
</script>

<style lang="scss">
#reservation-confirmation-modal {
  .custom-modal-header {
    padding: 2rem 2rem 0rem 2rem;
    border-bottom: none;
    color: $black;

    .modal-title {
      font-weight: 700;
    }
  }
}
</style>

<style lang="scss" scoped>
.disclamer-info {
  font-size: 10px;
}

.info-icon {
  fill: $dodger-blue;
  transform: scale(0.8);
}

.reservation p {
  margin-bottom: 0;
}

.reservation:last-child .service-info {
  margin-bottom: 0px;
}

.price-detail {
  font-size: 12px;
}
</style>
