<template>
  <quick-message :message="message" show type="info" with-icon />
</template>

<script>
import QuickMessage from "./QuickMessage";
import format from "date-fns/format";
import { getTimezone } from "../utils/time";

export default {
  name: "ReservationTimeZoneMessage",

  components: {
    QuickMessage
  },

  computed: {
    timeZoneOffset() {
      return format(Date.now(), "O");
    },
    message() {
      return `${this.getTimeZoneName()} ${
        this.timeZoneOffset
      }: Las reservaciones se crearán en tu zona horaria`;
    }
  },

  methods: {
    getTimeZoneName() {
      const timeZone = getTimezone();
      switch (timeZone) {
        case "America/Tijuana":
          return "Zona horaria del Noroeste en Tijuana";
        case "America/Mazatlan":
          return "Zona horaria del Pacífico en Mazatlán";
        case "America/Mexico_City":
          return "Zona horaria del Centro en CDMX";
        case "America/Cancun":
          return "Zona horaria del Centro en Cancún";
        default:
          return timeZone;
      }
    }
  }
};
</script>
