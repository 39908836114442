<template>
  <div class="bg-white rounded">
    <hour-rent-reserve />
  </div>
</template>

<script>
import HourRentReserve from "./HourRentReserve";

export default {
  name: "HourRent",

  components: {
    HourRentReserve
  },

  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab } });
    }
  }
};
</script>
