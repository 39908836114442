<template>
  <div>
    <hour-rent-calendar
      :modal-open="requestPanelProps.show"
      @event-drop="handleEventDrop"
      @event-render="handleEventRender"
      @event-resize="handleEventResize"
      @event-selected="handleEventSelected"
      @reserve="showConfirmationModal = true"
      @quick-reservation="handleQuickReservation"
    />
    <hour-rent-request-panel
      v-if="requestPanelProps.show"
      :show="requestPanelProps.show"
      :left="requestPanelProps.left"
      :top="requestPanelProps.top"
      :event="selectedEvent"
      :reservation-vehicle-type="vehicleType"
      @cancel="handleRequestPanelCancel($event)"
      @save="handleRequestPanelSave($event)"
      @clickaway="requestPanelProps.show = false"
    />
    <reservation-confirmation-modal
      v-model="showConfirmationModal"
      :address="defaultAddress"
      :services="eventsPendingToReserve"
      :user="user"
      :visible="showConfirmationModal"
      :vehicle-type="vehicleType"
      :balance="isBranch ? companyWallet : walletBalance"
      :reserve-func="handleReserve"
      :estimate-func="handleEstimation"
      @response-reservation="handleConfirmation"
    >
      <template v-slot:wallet-deposit="data">
        <wallet-balance class="mb-3" size="sm" />
        <quick-message
          class="my-2"
          :show="walletBalance <= data.total"
          message="Saldo insuficiente"
          with-icon
          type="error"
        />
      </template>
    </reservation-confirmation-modal>
    <b-modal
      id="info-modal"
      ref="info-modal"
      v-model="show"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <div class="p-3">
        <h5>{{ title }}</h5>
        <p class="mt-4">
          {{ message }}
        </p>
        <div class="w-100 mt-2 d-flex flex-row-reverse">
          <router-link
            :to="{ name: 'dashboard', query: { tab: 'reservaciones' } }"
          >
            <z-button @click="show = !show">
              Aceptar
            </z-button>
          </router-link>
        </div>
      </div>
    </b-modal>
    <notifications group="hour-rent-notifications" />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import moment from "moment";
import _cloneDeep from "lodash/cloneDeep";
import PlatformSchedule from "@/constants/platform/schedule";
import Http from "@zubut/common/src/constants/http";
import ServiceType from "@zubut/common/src/constants/services/type";
import VehicleTypes from "@/constants/vehicles/type";
import HourRentCalendar from "./HourRentCalendar";
import HourRentRequestPanel from "./HourRentRequestPanel";
import WalletBalance from "@/app/components/WalletBalance";
import ReservationConfirmationModal from "@zubut/common/src/components/ReservationConfirmationModal";
import Reservations from "@/services/reservations";
import Clients from "@/services/clients";
import notifyMixin from "@/mixins/notify";
import { format } from "@zubut/common/src/utils/time";

export default {
  name: "HourRentReserve",

  components: {
    HourRentCalendar,
    HourRentRequestPanel,
    WalletBalance,
    ReservationConfirmationModal
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      show: false,
      message: "",
      title: "",
      reservationOrigin: null,
      requestPanelProps: {
        show: false,
        left: 0,
        top: 0
      },
      selectedEventId: -1,
      showConfirmationModal: false,
      openWalletModal: false,
      vehicleType: VehicleTypes.NUM_MOTORCYCLE,
      companyWallet: null,
      specialSchedule: this.$store.state.user.additional?.specialSchedule
        ? this.$store.state.user.additional.specialSchedule
        : false
    };
  },

  computed: {
    events() {
      return this.$store.state.rents.events;
    },

    defaultAddress() {
      const defaultAddress = this.$store.getters["user/getDefaultAddress"];
      const savedAddress = this.$store.state.rents.defaultAddress;

      if (savedAddress) {
        return savedAddress;
      }

      if (defaultAddress.length > 0) {
        return defaultAddress[0];
      }

      return null;
    },

    eventsPendingToSave() {
      const notSavedEvents = this.$store.getters["rents/getNotSavedEvents"];
      return notSavedEvents.length;
    },

    favoriteAddresses() {
      return this.$store.getters["user/getAvailableAddresses"];
    },

    eventsPendingToReserve() {
      const nonReservedEvents = this.$store.getters[
        "rents/getNonReservedEvents"
      ];
      return nonReservedEvents;
    },

    selectedEvent() {
      const event = this.$store.getters["rents/getEvent"](this.selectedEventId);
      return event;
    },

    user() {
      return this.$store.state.user;
    },

    walletBalance() {
      return this.$store.getters["user/getWalletAmount"];
    },

    isBranch() {
      return this.$store.getters["user/isBranch"];
    }
  },

  beforeMount() {
    this.getFavoriteAddresses();
  },

  mounted() {
    if (this.isBranch) this.getCompanyWallet();
    this.reservationOrigin = this.defaultAddress;
  },

  methods: {
    handleEstimation(data) {
      return Reservations.estimation(data);
    },

    createNewEvent(start, end) {
      let address = null;
      let saved = false;
      if (this.defaultAddress) {
        address = _cloneDeep(this.defaultAddress);
        saved = true;
      } else {
        this.$store.dispatch("rents/removeNotSavedEvents");
      }
      return {
        id: this.$store.state.rents.eventId,
        start: start.format(),
        end: end.format(),
        address,
        cost: 0,
        drivers: 1,
        saved,
        reserved: false
      };
    },

    formatEvent(event) {
      if (event.extendedProps) {
        return {
          end: format(event.end._i || event.end, "yyyy-MM-dd'T'HH:mm:ss"),
          id: parseInt(event.id, 10),
          start: format(event.start._i || event.start, "yyyy-MM-dd'T'HH:mm:ss"),
          title: _cloneDeep(event.extendedProps.address),
          cost: event.extendedProps.cost,
          address: _cloneDeep(event.extendedProps.address),
          drivers: event.extendedProps.drivers,
          saved: event.extendedProps.saved,
          cost: event.extendedProps.cost
        };
      } else {
        return {
          end: moment(event.end._i || event.end).format(),
          id: parseInt(event.id, 10),
          start: moment(event.start._i || event.start).format(),
          title: event.address,
          address: event.address,
          cost: event.cost,
          drivers: event.drivers,
          saved: event.saved,
          reserved: event.reserved
        };
      }
    },

    getFavoriteAddresses() {
      if (this.favoriteAddresses.length <= 0) {
        this.$store.dispatch("user/getFavoriteAddresses").catch(err => {
          this.$captureError(err);
        });
      }
    },

    getOffset(el) {
      const box = el.getBoundingClientRect();
      const requestPanelWidth = 412;
      const position = {
        top:
          box.top +
          window.pageYOffset -
          document.documentElement.clientTop -
          100,
        left:
          box.left +
          window.pageXOffset -
          document.documentElement.clientLeft +
          box.width
      };
      const isOffScreenRight =
        position.left > window.innerWidth - requestPanelWidth - 100;

      if (isOffScreenRight) {
        position.left = position.left - box.width - requestPanelWidth - 10;
      }

      return position;
    },

    handleEventRender(event, element) {
      const start = moment(event.start);
      event.start = start;
      const end = moment(event.end);
      const startDay = moment(start).startOf("day");
      const startCurrentDay = moment().startOf("day");
      const daysDif = startDay.diff(startCurrentDay, "days");

      event.cost = event.cost || 0;
      event.title = event.address
        ? event.address.company || event.address.name
        : "Seleccione un origen";

      if (daysDif > 1) {
        const hourDifference = end.diff(start, "hours");
        const eventIndex = this.events.findIndex(
          ev => ev.id === parseInt(event.id, 10)
        );

        if (
          hourDifference < PlatformSchedule.MIN_RESERVATION_HOURS ||
          hourDifference > PlatformSchedule.MAX_RESERVATION_HOURS
        ) {
          if (hourDifference < PlatformSchedule.MIN_RESERVATION_HOURS) {
            event.end = moment(start).add(
              PlatformSchedule.MIN_RESERVATION_HOURS,
              "hours"
            );
          } else {
            event.end = moment(start).add(
              PlatformSchedule.MAX_RESERVATION_HOURS,
              "hours"
            );
          }
          if (eventIndex === -1 && this.isValidDate(start, event.end)) {
            const newEvent = this.createNewEvent(start, event.end);
            this.$store.commit("rents/incrementEventId");
            this.$store.dispatch("rents/updateEvents", newEvent);
            return false;
          }
          return false;
        }
        return true;
      }
      return false;
    },

    handleEventResize(event, delta, revert) {
      const eventIndex = this.events.findIndex(
        ev => ev.id === parseInt(event.id, 10)
      );
      if (eventIndex !== -1) {
        const week = this.specialSchedule
          ? PlatformSchedule.specialWeek
          : PlatformSchedule.week;
        const start = moment(event.start);
        const end = moment(event.end);
        const minutesDifference = moment(event.end).diff(
          moment(event.start),
          "minutes"
        );
        const isWithinSameDay =
          start.day() === end.day() ||
          (moment(end)
            .startOf("day")
            .diff(moment(start).startOf("day"), "days") === 1 &&
            end.hour() == 0);
        if (
          event.extendedProps.reserved ||
          minutesDifference < PlatformSchedule.MIN_RESERVATION_MINUTES ||
          minutesDifference > PlatformSchedule.MAX_RESERVATION_MINUTES ||
          !isWithinSameDay ||
          end.hours() > week.to
        ) {
          revert();
        } else {
          this.$store.dispatch("rents/updateEvents", this.formatEvent(event));
        }
      }
    },

    handleEventDrop(event, delta, revert) {
      const start = moment(event.start);
      const end = moment(event.end);
      const startEventDay = moment(start).startOf("day");
      const startCurrentDay = moment().startOf("day");
      const daysDif = startEventDay.diff(startCurrentDay, "days");
      if (
        event.extendedProps.reserved ||
        daysDif <= 1 ||
        !this.isValidDate(start, end)
      ) {
        revert();
      } else {
        this.$store.dispatch("rents/updateEvents", this.formatEvent(event));
      }
      this.requestPanelProps.show = false;
    },

    handleEventSelected(event, element) {
      if (!event.extendedProps.reserved) {
        this.selectedEventId = parseInt(event.id, 10);
        this.positionRequestPanel(element);
        this.requestPanelProps.show = false;
        this.$nextTick(() => {
          this.requestPanelProps.show = true;
        });
      }
    },

    handleRequestPanelCancel(isReservedEvent) {
      this.requestPanelProps.show = false;
      if (!isReservedEvent) {
        this.$store.dispatch("rents/deleteEvent", this.selectedEvent);
        if (!this.eventsPendingToReserve.length > 0) {
          this.$store.commit("rents/removeDefaultEventAddress");
        }
      }
    },

    handleRequestPanelSave({ data, closeRequestPanel, vehicleType }) {
      this.selectedEvent.address = data.address.address || "";
      let event = this.formatEvent(this.selectedEvent);
      event = { ...event, ..._cloneDeep(data) };
      event.saved = true;
      if (closeRequestPanel) {
        this.requestPanelProps.show = false;
      }
      this.vehicleType = vehicleType;
      this.$store.dispatch("rents/updateEvents", event);
    },

    handleReserve(requestData) {
      return this.$store
        .dispatch("rents/reserveAllEvents", requestData)
        .then(() => {
          this.handleConfirmation({
            title: "Reservación creada",
            message: `Tu reservación se creó con éxito, puedes verla en la pestaña de ${ServiceType.RENT_PER_HOUR}`
          });
        })
        .catch(err => {
          this.$captureError(err);
          this.handleConfirmation({
            title: "Error en la reservación",
            message: err.message
          });
        })
        .finally(() => {
          this.showConfirmationModal = false;
        });
    },

    handleConfirmation(data) {
      this.message = data.message;
      this.title = data.title;
      this.showModal();
    },

    handleQuickReservation(reservationWeek) {
      this.$store
        .dispatch("rents/fetchLastReservation", reservationWeek)
        .then(() => {
          this.handleReserve();
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.message =
              "Para reservar de manera rápida es necesario tener por lo menos una reservación";
            this.title = "No tienes reservaciones";
          }
          if (err.statusCode === Http.BAD_REQUEST) {
            this.message =
              "Tu ultima reservación tiene días que ya no son validos para el periodo de tiempo seleccionado, cambia de fechas o selecciona los días de este periodo que quieras reservar";
            this.title = "Reservación fuera de tiempo";
          }
          this.showModal();
        });
    },

    isValidDate(start, end) {
      const sunday = this.specialSchedule
        ? PlatformSchedule.specialWeek
        : PlatformSchedule.sunday;
      const week = this.specialSchedule
        ? PlatformSchedule.specialWeek
        : PlatformSchedule.week;
      const startTime = start.hours() + start.minutes() / 100;
      const endTime = end.hours() + end.minutes() / 100;
      const isWithinServiceHours = startTime >= week.from && endTime <= week.to;
      const isWithinSameDay =
        start.day() === end.day() ||
        (moment(end)
          .startOf("day")
          .diff(moment(start).startOf("day"), "days") === 1 &&
          end.hour() === 0);
      const isWithinSundayServiceHours =
        start.day() === 0
          ? startTime >= sunday.from && endTime <= sunday.to
          : true;
      return (
        isWithinSameDay && isWithinServiceHours && isWithinSundayServiceHours
      );
    },

    positionRequestPanel(element) {
      const position = this.getOffset(element);
      this.requestPanelProps.left = position.left;
      this.requestPanelProps.top = position.top;
    },

    showModal() {
      this.show = true;
    },

    getCompanyWallet() {
      Clients.getCompanyWalletAmount()
        .then(res => {
          this.companyWallet = res.amount / 100;
        })
        .catch(err => {
          this.$captureError(err);
        });
    }
  }
};
</script>
